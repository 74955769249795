import { UserType } from "../typings";
import { axios } from "./axios";

type LoginResponse = {
  success: boolean;
  code: number;
  psdata: {
    status: boolean;
    message: string;
    user: UserType;
  };
};
export class AuthenticationRequest {
  static async login(email: string, password: string): Promise<LoginResponse> {
    try {
      const response = await axios.post("/login",
        {
          email,
          password,
        },
      );
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}
