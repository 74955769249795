import { Button, Grid, Link, Typography } from "@mui/material";

const PointsComponents = (props: {
  points: number;
  convertPoints: () => void;
}) => {
  return (
    <Grid container direction={"row"} justifyContent="space-between">
      <Grid item>
        <Typography>
          Totale punts:{" "}
          <Typography variant="overline" fontSize="1rem" color="red">
            {props.points}
          </Typography>
        </Typography>
      </Grid>
      <Grid item>
        <Link onClick={props.convertPoints}>
          <Button variant="contained" size="small">
            Converti i punti
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export default PointsComponents;
