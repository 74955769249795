import { Box, Button, Grid } from "@mui/material";
import IconIosHome from "./icon_ios_home";
import IconIosShare from "./icon_ios_share";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { SlideContainer } from "./slide_container";
import ReactPlayer from "react-player";
const color = "#e6e6e6";

interface PropsInstallazione {
  onEnd: () => void;
}
const InstallazioneIos = (props: PropsInstallazione) => {
  const font_size = "1.5rem";

  return (
    <Grid
      minHeight={"100vh"}
      minWidth={"100vw"}
      style={{ backgroundColor: color }}
    >
      <Swiper
        modules={[Pagination]}
        pagination
        slidesPerView={1}
        spaceBetween={0}
      >
        <SwiperSlide>
          <SlideContainer background_color={color}>
            <Box display="flex" justifyContent="center">
              <Box
                component="img"
                sx={{
                  width: 120,
                }}
                alt="DogBauer"
                src="/android-icon-192x192.png"
              />
            </Box>
          </SlideContainer>
        </SwiperSlide>
        <SwiperSlide>
          <SlideContainer background_color={color}>
            <Box textAlign={"justify"} fontSize={font_size} whiteSpace="normal">
              Per utilizzare l'app di DogBauer è necessario installala sul
              telefono.
            </Box>
            <Box textAlign={"center"} fontSize={font_size}>
              E' molto facile.
            </Box>
          </SlideContainer>
        </SwiperSlide>
        <SwiperSlide>
          <SlideContainer background_color={color}>
            <Box textAlign={"center"} fontSize={font_size}>
              <Box component={"p"}>
                Tocca l'icona <IconIosShare /> su Safari
              </Box>
              <Box component={"p"}>
                Poi usa l'icona <IconIosHome /> per installare.{" "}
              </Box>
              <Box component={"p"}>
                Completata l'operazione trovi l'applicazione nella schermata
                iniziale.
              </Box>
              {/* <Box component={"p"}>
                Nella prossima slide trovi un video che ti spiega come fare.
              </Box> */}
            </Box>
          </SlideContainer>
        </SwiperSlide>
        {/* <SwiperSlide>
          <SlideContainer background_color={color}>
            <Box justifyContent={"center"} display="flex">
              <ReactPlayer
                url="/ios_install.mp4"
                height={"80%"}
                width={"80%"}
                controls
              />
            </Box>
          </SlideContainer>
        </SwiperSlide> */}

        <SwiperSlide>
          <SlideContainer background_color={color}>
            <Box textAlign={"center"}>
              <Box component={"p"} mb={3} fontSize={font_size}>
                Se hai fatto tutto puoi accedere all'app. La trovi sul tuo
                telefono.
              </Box>
              <Box component={"p"} mb={3}>
                <Button variant="contained" onClick={props.onEnd}>
                  chiudi
                </Button>
              </Box>
            </Box>
          </SlideContainer>
        </SwiperSlide>
      </Swiper>
    </Grid>
  );
};

export default InstallazioneIos;
