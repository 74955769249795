import { CouponsType } from "../typings";
import { axios } from "./axios";

type CouponResponse = {
  code: number;
  coupons: CouponsType[];
};

type PointesType = {
  code: number;
  points: number;
  success: boolean;
};

export class LoyaltyPoints {
  static async getCoupons(): Promise<CouponResponse> {
    try {
      const response = await axios.get("/coupons");

      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  static async getTotalPoints(): Promise<PointesType> {
    try {
      const response = await axios.get("/total-points");
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  static async getOrderPoints(id: string): Promise<PointesType> {
    try {
      const response = await axios.get(`/order-points?id_order=${id}`);
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  static async convertPoints(): Promise<Boolean> {
    try {
      const response = await axios.get(`/convert-points`);
      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}
