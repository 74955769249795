/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Product } from "../../../typings";
import ShowMoreComponent from "../../showMore/ShowMore.component";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import React, { useContext, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { ProductApi } from "../../../api/Product.api";

import { DogBauerContext } from "../../../context/DogBauer.context";
import ProductImages from "./ProductImages";
import { ProductPrice } from "../ProductPrice";

type ProductCardProps = {
  product: Product;
};
const ProductDetailCard = ({ product }: ProductCardProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { cart, setCart } = useContext(DogBauerContext);
  console.log(product);
  const AddToCart = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: Product
  ) => {
    event.preventDefault();
    setLoading(true);
    const { id_product } = item;

    await ProductApi.addToCart(id_product).then((data) => {
      if (data.code === 200 && data.success) {
        toast.success("Product added successful");
        ProductApi.getCart().then((response) => {
          setCart(response.psdata);
        });
      } else {
        toast.error("something went wrong");
      }
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    });
  };
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-right"
        autoClose={800}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <Card>
        <CardMedia>
          <Box my={2}>
            <ProductImages images={product.images} />
          </Box>
        </CardMedia>
        <CardContent style={{ backgroundColor: "#f5f5f5" }}>
          <Typography>{product.name}</Typography>
          <Typography variant="caption">
            Spese di spedizione: GRATIS (escluso Isole e Calabria)
          </Typography>
          <Box mb={2} />
          {product.packing && (
            <Typography>Confezione:{product.packing}</Typography>
          )}
          <ProductPrice
            price={product.price}
            discount_price={product.discount_price}
          />
        </CardContent>
        <ShowMoreComponent
          loading={loading}
          product={product}
          AddToCart={AddToCart}
        />
        <Box my={1} />
        <CardActionArea>
          <Box>
            <Button
              fullWidth
              disabled={loading}
              variant="contained"
              onClick={(event) => AddToCart(event, product)}
            >
              Aggiungi al carrello
            </Button>
          </Box>
        </CardActionArea>
      </Card>
    </React.Fragment>
  );
};

export default ProductDetailCard;
