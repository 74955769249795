type Props = {
  value: number;
};

const CurrencyFormat = ({ value }: Props) => {
  const Price = new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
  }).format(value);

  return <div>{Price}</div>;
};

export default CurrencyFormat;
