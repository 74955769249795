import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { LoyaltyPoints } from "../../api/LotalityPoint.api";
import { AuthStorage } from "../storage/AuthStorage";
export type Product = {
  quantity: number;
  productDetail: string;
};

type OrderProps = {
  total: string;
  // points: string;
  code: string;
  date: string;
  // products: Product[];
  status: string;
  order_id: string;
};

export const OrderCard = (props: OrderProps) => {
  const Icon = {
    fontSize: "75px",
    alignSelf: "center",
  };

  const date = new Date(props.date).toLocaleDateString();
  const [points, setPoints] = useState<number>(0);
  const authStorage: AuthStorage = AuthStorage.getInstance();
  useEffect(() => {
    const fetchPoints = async () => {
      await LoyaltyPoints.getOrderPoints(props.order_id).then((response) => {
        if (response.code == 410) {
          authStorage.removeUser();
        }
        // eslint-disable-next-line no-extra-boolean-cast
        if (response.success) {
          setPoints(response.points);
        }
      });
    };
    fetchPoints();
  }, []);
  const price = new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
  }).format(Number(props.total));

  const handleReorder = () => {
    toast.success("Tra pochi secondi verrai ridirezionato al checkout");
  };
  const gridRow = (
    text: string | JSX.Element,
    value: number | string | JSX.Element
  ) => {
    return (
      <Grid container direction={"row"} justifyContent="space-between" mb={1}>
        <Grid item xs={4}>
          {text}
        </Grid>
        <Grid item xs={8} textAlign="end">
          {value}
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid>
      <ToastContainer />
      <Card>
        <CardContent>
          {gridRow(
            <ShoppingCartIcon style={Icon} fontSize="inherit" />,
            <Grid>
              <Grid>
                <Typography variant="subtitle1">{props.code}</Typography>
                <Typography variant="subtitle2">{date}</Typography>
              </Grid>
            </Grid>
          )}
          {gridRow("Stato", props.status)}
          {gridRow("punti", points)}
          {gridRow("totale", price)}
        </CardContent>
        <CardActionArea>
          <Box p={2}>
            <Link
              href={`https://www.dogbauer.it/ordine?submitReorder=&id_order=${props.order_id}`}
              onClick={handleReorder}
            >
              <Button fullWidth variant="contained">
                Riordina
              </Button>
            </Link>
          </Box>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
