import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import { CartApi } from "../api/Cart.api";
import { Spinner } from "../components/Spinner";
import { CartDetail, CartResponse } from "../types/global.interface";
import { CartItemDataType } from "../types/global.types";
import Add from "@mui/icons-material/Add";
import { Toast } from "react-toastify/dist/components";
import { CartUpdateEnum } from "../types/enum";
interface CartActions {
  removeItem: (id_product: number) => void;
  addOneQuantity: (id_product: number) => void;
  removeOneQuantity: (id_product: number) => void;
  changeQuantity: (id_product: number, quantity: number) => void;
}
const CartPage = () => {
  const [cartData, setCartData] = useState<CartDetail | null>(null);
  const [loading, setLoading] = useState(true);
  const [snackMessage, setSnackMessage] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const initProcessCheckout = () => {
    alert("ok processo iniziato");
  };
  const cartActions: CartActions = {
    removeItem: async (id_product: number) => {
      const response = await CartApi.updateCart(
        CartUpdateEnum.Down,
        id_product,
        -1
      );
      if (response.success === true) {
        setCartData(Object.assign({}, response.psdata));
        setSnackMessage("prodotto rimosso");
      } else {
        setSnackMessage("errore in fase di rimozione");
      }

      setOpenSnack(true);
    },
    addOneQuantity: (id_product: number) => {
      alert("add 1 " + id_product);
    },
    removeOneQuantity: (id_product: number) => {
      alert("remove 1 " + id_product);
    },
    changeQuantity: (id_product: number, quantity: number) => {
      alert("change " + id_product + " qty " + quantity);
    },
  };

  //call api to get  items
  useEffect(() => {
    const fetch = async () => {
      const response = await CartApi.getCart();
      if (response.success === true) {
        setCartData(response.psdata);
      } else {
        console.log(response);
      }
      setLoading(false);
    };
    fetch();
  }, []);
  if (loading) {
    return Spinner(loading);
  }
  return (
    <Grid m={1} mb={5}>
      <Snackbar
        autoHideDuration={3000}
        message={snackMessage}
        open={openSnack}
        onClose={() => setOpenSnack(false)}
      />
      <Card>
        {CartHeader(cartData)}
        {CartContent(cartData, cartActions)}
        {CartFooter(initProcessCheckout)}
      </Card>
      {/* //display total price */}
      {/* //display checkout button */}
    </Grid>
  );
};

export default CartPage;
interface CartProductDataProps {
  item: CartItemDataType;
  cartActions: CartActions;
}

const CartProductData = ({
  item,
  cartActions,
}: CartProductDataProps): JSX.Element => {
  return (
    <Grid container gap={3}>
      <Grid item display={"flex"} flexDirection="row" gap={3}>
        <Box>
          <img src={item.image_url} />
        </Box>
        <Box>
          <Box>
            <Typography mb={1}>{item.name}</Typography>
            <Typography component={"span"}>
              {item.formatted_price_amount}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item direction={"row"} container alignItems={"center"} gap={1}>
        <IconButton
          onClick={() => cartActions.removeOneQuantity(item.id_product)}
        >
          <RemoveIcon />
        </IconButton>
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          InputProps={{ style: { width: "4rem", textAlign: "center" } }}
          defaultValue={item.quantity}
          onChange={(e) => {
            const qty = parseInt(e.currentTarget.value);
            cartActions.changeQuantity(item.id_product, qty);
          }}
        />
        <IconButton onClick={() => cartActions.addOneQuantity(item.id_product)}>
          <AddIcon />
        </IconButton>
        <Button
          variant="outlined"
          size="small"
          onClick={() => cartActions.removeItem(item.id_product)}
        >
          rimuovi
        </Button>
      </Grid>
    </Grid>
  );
};
function CartFooter(initProcessCheckout: () => void) {
  return (
    <CardActionArea>
      <Button fullWidth variant="contained" onClick={initProcessCheckout}>
        Procedi all'ordine{" "}
      </Button>
    </CardActionArea>
  );
}

function CartContent(cartData: CartDetail | null, cartActions: CartActions) {
  return (
    <CardContent>
      <List>
        {/* //display cart items */}
        {cartData?.products.map((item) => (
          <ListItem>
            <Box mb={2}>
              <CartProductData
                item={item}
                key={uuid()}
                cartActions={cartActions}
              />
            </Box>
          </ListItem>
        ))}
      </List>
    </CardContent>
  );
}

function CartHeader(cartData: CartDetail | null) {
  return (
    <CardHeader
      style={{ backgroundColor: "#f5f5f5" }}
      title={
        <React.Fragment>
          <Typography variant="h3">Il tuo carrello</Typography>
          <br />
          <Typography>
            Totale provvisorio: {cartData?.totals.total_including_tax.value}
            <br />
            (IVA inclusa)
          </Typography>
        </React.Fragment>
      }
    />
  );
}
