import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CouponsType } from "../../../typings";
import CurrencyFormat from "../../currentcy_formater/CurrencyFormat";

type Props = {
  coupon: CouponsType;
};

const CouponCard = ({ coupon }: Props) => {
  const date_from = new Date(coupon.date_from).toLocaleDateString();
  const date_to = new Date(coupon.date_to).toLocaleDateString();
  const flex = { display: "flex", justifyContent: "space-between" };
  let status = "";
  if (coupon.available === true) {
    status = "non usato";
  } else {
    status = "utilizzato";
  }

  return (
    <Grid mb={2}>
      <Card variant="outlined" style={{ boxShadow: "none" }}>
        <CardContent>
          {infoRowCoupon("Codice", coupon.code)}
          {infoRowCoupon("Valido dal", date_from)}
          {infoRowCoupon("Valido al", date_to)}
          {infoRowCoupon("Stato", status)}
          {infoRowCoupon(
            "valore",
            <CurrencyFormat value={Number(coupon.amount)} />
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};
const infoRowCoupon = (text: string, data: string | number | JSX.Element) => {
  return (
    <Grid container direction="row" justifyContent="space-between" mb={1}>
      <Grid item>{text}</Grid>
      <Grid item>{data}</Grid>
    </Grid>
  );
};
export default CouponCard;
