import { createTheme, ThemeOptions } from "@mui/material";

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#D31821",
    },
    secondary: {
      main: "#18D3CA",
    },
    text: {
      primary: "#252525",
    },
  },
  typography: {
    htmlFontSize: 15,
    h1: {
      fontSize: "3.5rem",
    },
    h2: {
      fontSize: "2.8rem",
    },
    h3: {
      fontSize: "2.4rem",
    },
    h4: {
      fontSize: "2rem",
    },
    body1: {
      fontWeight: 500,
    },
    subtitle1: {
      fontWeight: 500,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          height: "100%",
          backgroundColor: "#ffffff",
          borderColor: "#37373742",
          boxShadow: "1px 1px 7px #dcdcdc",
          display: "flex",
          flexDirection: "column",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          color: "primary",
          marginBottom: "20px",
          fontSize: "1.2rem",
        },
        subheader: {
          color: "text",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "10px",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
    },
  },
};
export default createTheme(themeOptions, {});
