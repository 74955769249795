import { Box, Card, CardContent, Typography } from "@mui/material";
import PointsComponents from "../PointsComponents";
import CouponsComponents from "./CouponsComponents";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { LoyaltyPoints } from "../../../api/LotalityPoint.api";
import { AuthStorage } from "../../storage/AuthStorage";
import { CouponsType } from "../../../typings";
const Coupons = () => {
  const [points, setPoints] = useState<number>(0);
  const [coupons, setCoupons] = useState<CouponsType[]>([]);
  const authStorage: AuthStorage = AuthStorage.getInstance();
  useEffect(() => {
    let cancelled = false;
    const fetchCoupons = async () => {
      await LoyaltyPoints.getTotalPoints().then((response) => {
        if (!cancelled) {
          if (response.success) setPoints(response.points);
        }
      });
      await LoyaltyPoints.getCoupons().then((response) => {
        if (!cancelled) {
          if (response.code == 410) {
            authStorage.removeUser();
          }
          setCoupons(response.coupons);
        }
      });
    };

    fetchCoupons();

    return () => {
      cancelled = true;
    };
  }, [points]);
  const convertPoints = async () => {
    await LoyaltyPoints.convertPoints();
    toast.success("Conversione punti avvenuta");
    setPoints(-1);
  };
  return (
    <Card>
      <ToastContainer />
      <Box pl={2} pt={2}>
        <Typography variant="h4">Punti fedeltà</Typography>
      </Box>
      <CardContent>
        <PointsComponents points={points} convertPoints={convertPoints} />
        <CouponsComponents coupons={coupons} />
      </CardContent>
    </Card>
  );
};

export default Coupons;
