import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Searchbar from "../components/product_list/Searchbar";
import RepeatOrder from "../components/order_repeat/RepeatOrder";
import ProductList from "../components/product_list/ProductList";
import { ID_CATEGORY_DEFAULT } from "../constants/global";

const ProductsPage = () => {
  const { category_id = ID_CATEGORY_DEFAULT } = useParams();
  const [query, setQuery] = useState("");
  return (
    <Grid>
      <Grid>
        <Searchbar setQuery={setQuery} />
      </Grid>
      {!query && (
        <Grid>
          <RepeatOrder />
        </Grid>
      )}
      <Grid>
        <ProductList category_id={category_id} filter={query} />
      </Grid>
    </Grid>
  );
};

export default ProductsPage;
