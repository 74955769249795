import {
  CardActionArea,
  CardActions,
  CardHeader,
  Link,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Box } from "@mui/system";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { TypeProductData } from "../../types/global.types";
import { Product } from "../../typings";
import { ProductPrice } from "./ProductPrice";

interface IProductCardParams {
  product_data: TypeProductData;
}

const ProductCard = (props: IProductCardParams) => {
  const { product_data } = props;
  return (
    <Link component={RouterLink} to={`/products/${product_data.id_product}`}>
      <Card>
        <CardMedia
          component="img"
          src={product_data.cover.url}
          style={{ width: "90%", padding: "10px" }}
          alt="green iguana"
        />
        <CardContent>{product_data.name}</CardContent>
        <CardActions disableSpacing sx={{ mt: "auto" }}>
          <ProductPrice price={product_data.price} />
        </CardActions>
      </Card>
    </Link>
  );
};

export default ProductCard;
