import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, InputAdornment, TextField } from "@mui/material";

type SearchbarProps = {
  // query: string;
  setQuery: (query: string) => void;
};
const Searchbar = ({ setQuery }: SearchbarProps) => {
  return (
    <Box m={2} mb={4}>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        fullWidth
        variant="standard"
        placeholder="ricerca"
        type="text"
        onChange={(e) => setQuery(e.target.value)}
      />
    </Box>
  );
};

export default Searchbar;
