import { Box, Grid, Typography } from "@mui/material";
import { ReorderDetailProps } from "../../typings";

export const RepeatOrderDetail = ({
  imageUrl,
  name,
  quantity,
}: ReorderDetailProps) => {
  return (
    <Grid container mb={5}>
      <Grid item xs={6}>
        <img src={imageUrl} alt="" />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="caption">{name}</Typography>
        <Grid container mt={2}>
          <Box>
            <Typography>quantità:</Typography>
          </Box>
          <Box ml={3}>
            <Typography>{quantity}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
