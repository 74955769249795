import { Box, Container, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import React from "react";
import { isDesktop, isTablet } from "react-device-detect";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { DogBauerProvider } from "./context/DogBauer.context";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import globalTheme from "./theme/global.theme";
import { ConsentOptions, ConsentProvider } from "react-hook-consent";
import { ConsentWrapper } from "./components/ConsentWrapper";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    {isDesktop || isTablet ? (
      <Container component="main" maxWidth="md">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <Typography variant="h2" textAlign={"center"}>
            spiacente ma questa applicazione è accessibile solo da mobile
          </Typography>
        </Box>
      </Container>
    ) : (
      <ThemeProvider theme={globalTheme}>
        <DogBauerProvider>
          <BrowserRouter>
            <ConsentWrapper>
              <App />
            </ConsentWrapper>
          </BrowserRouter>
        </DogBauerProvider>
      </ThemeProvider>
    )}
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
