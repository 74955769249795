/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, Grid, Link, TextField, Typography } from "@mui/material";
import React, { FormEvent, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { AuthenticationRequest } from "../../api/UserAuth";
import { DogBauerContext } from "../../context/DogBauer.context";
import { AuthStorage } from "../storage/AuthStorage";
import { useConsent } from "react-hook-consent";

const LoginComponent = () => {
  const authStorage: AuthStorage = AuthStorage.getInstance();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [loading, setLoading] = React.useState<boolean>(false);
  const history = useNavigate();
  const { setUser } = useContext(DogBauerContext);

  const SignIn = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);
    const response = await AuthenticationRequest.login(email, password);
    if (response.code === 200) {
      toast.success("Login Successful");
      authStorage.setUser(JSON.stringify(response.psdata.user));
      setUser(response.psdata.user);
      history("/");
    } else {
      toast.error("Login Failed");
    }

    setLoading(false);
    setEmail("");
    setPassword("");
  };

  return (
    <Grid mt={3}>
      <ToastContainer
        position="top-right"
        autoClose={2200}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form method="POST" onSubmit={SignIn}>
        <Grid mb={2}>
          <Typography variant="subtitle1">Email</Typography>
          <TextField
            spellCheck
            fullWidth
            placeholder={"la tua mail"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid mb={2}>
          <Typography variant="subtitle1">Password</Typography>
          <TextField
            spellCheck
            type="password"
            fullWidth
            placeholder={"la tua password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid mb={2}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
          >
            Login
          </Button>
        </Grid>
        <Grid>
          <Link href="https://www.dogbauer.it/password-recupero">
            <Typography variant="caption">Dimenticata la password?</Typography>
          </Link>
          &nbsp;
          <Link href="https://www.iubenda.com/privacy-policy/85917876">
            <Typography variant="caption">Privacy</Typography>
          </Link>
        </Grid>
      </form>
    </Grid>
  );
};

export default LoginComponent;
