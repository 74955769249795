import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/Logo.png";
import InstallazioneAndroid from "../components/installazione/installazione_android";
import InstallazioneIos from "../components/installazione/installazione_ios";
import LoginComponent from "../components/login/Login.component";

import { AuthStorage } from "../components/storage/AuthStorage";
import { HOME_SLIDER } from "../constants/global";
import { getStorageItem, setStorageItem } from "../lib/storage";

const LoginScreen = () => {
  const history = useNavigate();
  const authStorage: AuthStorage = AuthStorage.getInstance();
  const [viewHomeSlider, setViewHomeSlider] = useState(
    getStorageItem(HOME_SLIDER) === "false" ||
      getStorageItem(HOME_SLIDER) === null
  );

  useEffect(() => {
    try {
      authStorage.getUser();
      history("/");
    } catch (error) {
      return;
    }
  }, []);
  const hideHomeSlider = () => {
    setStorageItem(HOME_SLIDER, false);
    setViewHomeSlider(false);
  };
  if (viewHomeSlider) {
    return (
      <React.Fragment>
        {isIOS && <InstallazioneIos onEnd={hideHomeSlider} />}
        {isAndroid && <InstallazioneAndroid onEnd={hideHomeSlider} />}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }} p={4}>
        <Grid container>
          <Grid textAlign={"center"}>
            <img src={Logo} alt="logo-image" />
            <Typography variant="h1">Benvenuto in Dog Bauer</Typography>
          </Grid>

          <LoginComponent />
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default LoginScreen;
