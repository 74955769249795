import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductApi } from "../api/Product.api";
import ProductDetailCard from "../components/product_list/product_card/ProductDetailCard";
import { AuthStorage } from "../components/storage/AuthStorage";
import { Product } from "../typings";
import { Spinner } from "../components/Spinner";

const ProductDetailScreen = () => {
  const { productId } = useParams();
  // const currentProduct = getProductById(Number(productId));
  const [currentProduct, SetCurrentProduct] = useState<Product>();
  const authStorage: AuthStorage = AuthStorage.getInstance();
  useEffect(() => {
    const fetchProductDetails = async () => {
      await ProductApi.getProductById(Number(productId)).then((response) => {
        if (response.code == 410) {
          authStorage.removeUser();
        }
        SetCurrentProduct(response.psdata);
      });
    };
    fetchProductDetails();
  }, [productId]);

  return (
    <Container maxWidth="xl">
      {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        currentProduct ? (
          <ProductDetailCard product={currentProduct} />
        ) : (
          <Container maxWidth="lg">{Spinner(true)}</Container>
        )
      }
    </Container>
  );
};

export default ProductDetailScreen;
