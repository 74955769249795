export class AuthStorage {
  private static instance: AuthStorage | undefined;

  private constructor() {}

  public static getInstance(): AuthStorage {
    if (!AuthStorage.instance) {
      AuthStorage.instance = new AuthStorage();
    }
    return AuthStorage.instance;
  }

  public setUser(user: string): void {
    window.localStorage.setItem("currentUser", user);
    window.localStorage.setItem("installed", "true");

    console.log(window.localStorage);
    
  }

  public getUser(): string[] {
    const data = window.localStorage.getItem("currentUser")?.split(" ");
    if (data) {
      return data;
    }
    throw new Error("No user logged");
  }
  public removeUser(): void {
    window.localStorage.removeItem("currentUser");
    window.localStorage.removeItem("installed");
    window.location.reload();
  }
}
