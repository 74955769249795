import { getReorderHistoryType, OrderHistoryType } from "../typings";
import { axios } from "./axios";

export class OrderHistoryApi {
  static async getOrderHistory(): Promise<OrderHistoryType> {
    try {
      const response = await axios.get(
        "/orderhistory",
      );

      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  static async getReorderHistory(
    order_id: string
  ): Promise<getReorderHistoryType> {
    try {
      const response = await axios.get(
        `/orderhistory?id_order=${order_id}`,
      );

      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}
