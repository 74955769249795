import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import { ProductImageProps } from "../../../typings";

const ProductImages = ({ images }: ProductImageProps) => {
  return (
    <Swiper className="mySwiper">
      {images.map((imgUrl, idx: number) => (
        <SwiperSlide
          style={{
            position: "relative",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          key={idx}
        >
          <img
            src={imgUrl.src}
            style={{
              height: "270px",
              width: "250px",
            }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ProductImages;
