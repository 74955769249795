import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { v4 as uuid } from "uuid";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { SlideContainer } from "./slide_container";
import { PropsInstallazione } from "./types";
const color = "#e6e6e6";

const InstallazioneAndroid = (props: PropsInstallazione) => {
  const font_size = "1.5rem";

  return (
    <Grid
      minHeight={"100vh"}
      minWidth={"100vw"}
      style={{ backgroundColor: color }}
    >
      <Swiper
        modules={[Pagination]}
        pagination
        slidesPerView={1}
        spaceBetween={0}
      >
        <SwiperSlide key={uuid()}>
          <SlideContainer background_color={color}>
            <Box display="flex" justifyContent="center">
              <Box
                component="img"
                sx={{
                  width: 120,
                }}
                alt="DogBauer"
                src="/android-icon-192x192.png"
              />
            </Box>
          </SlideContainer>
        </SwiperSlide>
        <React.Fragment>
          <SwiperSlide key={uuid()}>
            <SlideContainer background_color={color}>
              <Box
                component="p"
                textAlign={"center"}
                fontSize={font_size}
                whiteSpace="normal"
              >
                Per utilizzare l'app di DogBauer è necessario installala sul
                telefono.
              </Box>
              <Box component="p" textAlign={"center"} fontSize={font_size}>
                E' molto facile.
              </Box>
            </SlideContainer>
          </SwiperSlide>
          <SwiperSlide key={uuid()}>
            <SlideContainer background_color={color}>
              <Box textAlign={"center"} fontSize={font_size}>
                <Box component={"p"}>
                  Tocca <MoreVertIcon /> sul browser, in alto a destra.
                </Box>
                <Box component={"p"}>poi seleziona la voce: </Box>
                <Box component={"p"}>
                  "Aggiungi a schermata iniziale" oppure "Installa l'app".
                </Box>
                <Box component={"p"}>
                  Completata l'operazione trovi l'applicazione nella schermata
                  iniziale.
                </Box>
                {/* <Box component={"p"}>
                  Nella prossima slide trovi un video che ti spiega come fare.
                </Box> */}
                {/* <Box
                  component="img"
                  sx={{
                    width: 200,
                  }}
                  alt="DogBauer
                  src="/android_screenshot.png"
                /> */}
              </Box>
            </SlideContainer>
          </SwiperSlide>
          {/* <SwiperSlide key={uuid()}>
            <SlideContainer background_color={color}>
              <Box justifyContent={"center"} display="flex">
                <ReactPlayer
                  url="/android_install.mp4"
                  height={"80%"}
                  width={"80%"}
                  controls
                />
              </Box>
            </SlideContainer>
          </SwiperSlide> */}
        </React.Fragment>
        <SwiperSlide key={uuid()}>
          <SlideContainer background_color={color}>
            <Box textAlign={"center"} fontSize={font_size}>
              <Box component={"p"} mb={3}>
                Se hai fatto tutto puoi accedere all'app. La trovi nelle app del
                tuo telefono.
              </Box>
              <Box component={"p"} mb={3}>
                <Button variant="contained" onClick={props.onEnd}>
                  chiudi
                </Button>
              </Box>
            </Box>
          </SlideContainer>
        </SwiperSlide>
      </Swiper>
    </Grid>
  );
};

export default InstallazioneAndroid;
