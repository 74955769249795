import { Grid } from "@mui/material";
import { TypeProductRow } from "../../types/global.types";
import ProductCard from "./ProductCard";
interface IProductRow {
  row: TypeProductRow;
}

const ProductRow = (params: IProductRow) => {
  return (
    <Grid container spacing={1} p={1} alignItems={"stretch"} direction={"row"}>
      <Grid item xs={6}>
        <ProductCard product_data={params.row[0]} />
      </Grid>
      <Grid item xs={6}>
        <ProductCard product_data={params.row[1]} />
      </Grid>
    </Grid>
  );
};

export default ProductRow;
