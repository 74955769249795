import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/header/Header";
import SidebarDrawer from "./components/sidebar/SidebarDrawer";
import { DogBauerContext } from "./context/DogBauer.context";
import OrdersScreen from "./pages/OrdersPage";
import LoginScreen from "./pages/LoginPage";
import ProductDetailPage from "./pages/ProductDetailPage";
import { AuthStorage } from "./components/storage/AuthStorage";
import ProductsPage from "./pages/ProductsPage";
import CartPage from "./pages/CartPage";
import { useConsent } from "react-hook-consent";

const App = () => {
  const authStorage: AuthStorage = AuthStorage.getInstance();
  const { user, setUser } = useContext(DogBauerContext);
  const history = useNavigate();
  const { setConsent } = useConsent();

  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    // setConsent(["myid"]);
    if (window.location.pathname !== "/login") {
      try {
        const data = authStorage.getUser();
        setUser(data);
      } catch (error) {
        return history("/login");
      }
    }
  }, []);

  return (
    <React.Fragment>
      {Boolean(user) && (
        <Header opened={openDrawer} openDrawer={setOpenDrawer} />
      )}
      <Routes>
        <Route path="/orders" element={<OrdersScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/" element={<ProductsPage />} />
        <Route path="/categoria/:category_id" element={<ProductsPage />} />
        <Route path="/products/:productId" element={<ProductDetailPage />} />
        <Route path="/cart" element={<CartPage />} />
      </Routes>
      <SidebarDrawer open={openDrawer} toggleDrawer={setOpenDrawer} />
    </React.Fragment>
  );
};

export default App;
