import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
interface ProductPriceInterface {
  price: string;
  discount_price?: string;
}

export const ProductPrice = (props: ProductPriceInterface) => {
  return (
    <Grid container direction={"column"}>
      {props.discount_price && (
        <Grid item container direction={"row"} alignItems="center">
          <Typography variant="h6" pr={1} color={"secondary"}>
            {props.discount_price}
          </Typography>
          <Typography variant="body2">IVA inclusa</Typography>
        </Grid>
      )}
      <Grid item container direction={"row"} alignItems="center">
        {props.discount_price && (
          <Typography variant="body2" pr={1}>
            prima era
          </Typography>
        )}
        <Typography
          color={!props.discount_price ? "secondary" : ""}
          variant={props.discount_price ? "body2" : "subtitle1"}
          style={props.discount_price ? { textDecoration: "line-through" } : {}}
        >
          {props.price}
        </Typography>
        {!props.discount_price && (
          <Typography variant="body2" pl={1}>
            IVA inclusa
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
