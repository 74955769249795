import React from "react";
import {
  ConsentBanner,
  ConsentOptions,
  ConsentProvider,
  useConsent,
} from "react-hook-consent";
import "./../assets/style.css";
const consentOptions: ConsentOptions = {
  services: [
    {
      id: "myid",
      name: "Strettamente necessari",
      description:
        "Questi strumenti di tracciamento sono strettamente necessari per garantire il funzionamento e la fornitura del servizio che ci hai richiesto e, pertanto, non richiedono il tuo consenso.",
      // scripts: [
      // { id: "external-script", src: "https://myscript.com/script.js" },
      // ],
    },
    {
      id: "myid2",
      name: "Interazioni e funzionalità semplici",
      description:
        "Questi strumenti di tracciamento abilitano semplici interazioni e funzionalità che ti permettono di accedere a determinate risorse del nostro servizio e ti consentono di comunicare più facilmente con noi.",
      // cookies: [{ pattern: "example_cookie" }],
    },
    {
      id: "myid3",
      name: "Miglioramento dell’esperienza",
      description:
        "Questi strumenti di tracciamento ci permettono di offrire una user experience personalizzata migliorando la gestione delle impostazioni e consentendo l'interazione con network e piattaforme esterne.",
      // scripts: [
      //   { id: "external-script", src: "https://myscript2.com/script.js" },
      // ],
    },
    {
      id: "myid4",
      name: "Misurazione",
      description:
        "Questi strumenti di tracciamento ci permettono di misurare il traffico e analizzare il tuo comportamento con l'obiettivo di migliorare il nostro servizio.",
      // scripts: [
      //   { id: "external-script", src: "https://myscript2.com/script.js" },
      // ],
    },
    {
      id: "myid5",
      name: "Targeting e Pubblicità",
      description:
        "Questi strumenti di tracciamento ci consentono di fornirti contenuti commerciali personalizzati in base al tuo comportamento e di gestire, fornire e tracciare gli annunci pubblicitari.",
      // scripts: [
      //   { id: "external-script", src: "https://myscript2.com/script.js" },
      // ],
    },
  ],
  theme: "dark",
};
export const ConsentWrapper = ({ children }: { children: React.ReactNode }) => {
  const { toggleBanner } = useConsent();
  toggleBanner();
  return (
    <ConsentProvider options={{ ...consentOptions }}>
      {children}
      <ConsentBanner
        settings={{
          hidden: false,
          label: "Leggi di più",
          modal: {
            title: "Impostazioni dei cookie",
            approve: { label: "Accetta" },
            decline: { label: "Rifiuta" },
            approveAll: { label: "Accetta tutti" },
            description: "Qui puoi gestire le impostazioni dei cookie",
          },
        }}
        decline={{ label: "No" }}
        approve={{ label: "Si" }}
      >
        <>
          Utilizziamo cookie tecnici ed analytics anonimizzati (necessari) e,
          con il tuo consenso, cookie di profilazione (pubblicità) di terze
          parti. Puoi liberamente prestare, rifiutare o revocare il tuo
          consenso, in qualsiasi momento. Puoi acconsentire all’utilizzo di tali
          tecnologie utilizzando il pulsante “Accetta" oppure puoi chiudere il
          banner con la X mantenendo le impostazioni di default e continuare la
          navigazione senza cookie o altri strumenti di tracciamento diversi da
          quelli tecnici.
        </>
      </ConsentBanner>
    </ConsentProvider>
  );
};
