import { Product } from "../typings";
import { axios } from "./axios";
type ProductsResponse = {
  code: number;
  psdata: {
    products: Product[];
  };
};

//  for single product
type ProductResponse = {
  code: number;
  psdata: Product;
};

type AddToCartResponse = {
  code: number;
  success: boolean;
  psdata: {
    product: [];
  };
};
export class ProductApi {
  static async getProducts(
    idCategory: string | number
  ): Promise<ProductsResponse> {
    try {
      const url = `/categoryProducts?id_category=${idCategory}&page=1&with_all_images=0&image_size=home_default`;
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  static async getProductById(id: number): Promise<ProductResponse> {
    try {
      const response = await axios.get(
        `/productdetail?product_id=${id}&image_type=large&with_all_images=0`
      );
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  static async getProductByQuery(query: string): Promise<ProductsResponse> {
    try {
      const response = await axios.get(
        `/productSearch?s=${query}&resultsPerPage=10`
      );

      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  static async getProductByCategory(id: number): Promise<ProductsResponse> {
    try {
      const response = await axios.get(
        `/categoryProducts?id_category=${id}&page=1&with_all_images=0&image_size=home_default`
      );
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  static async addToCart(id: number): Promise<AddToCartResponse> {
    try {
      const response = await axios.get(
        `/cart?update=1&id_product=${id}&op=up&action=update&image_size=medium_default&qty=1`
      );
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  static async getCart(): Promise<any> {
    try {
      const response = await axios.get(`/cart?image_size=medium_default`);
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}
