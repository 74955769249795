import { CartUpdateEnum } from "../types/enum";
import { CartResponse } from "../types/global.interface";
import { axios } from "./axios";

export class CartApi {
  static async getCart(): Promise<CartResponse> {
    try {
      const response = await axios.get("/cart");

      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
  static async updateCart(
    action: CartUpdateEnum,
    id_product: number,
    quantity: number
  ): Promise<CartResponse> {
    try {
      const response = await axios.get(
        `/cart?op=${action}&update=${quantity}&id_product=${id_product}&action=update`
      );

      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}
