import React from "react";
import { Grid } from "@mui/material";
import { SyncLoader } from "react-spinners";

export function Spinner(loading: boolean) {
  return (
    <Grid alignContent={"center"} alignItems={"center"} textAlign={"center"}>
      <SyncLoader loading={loading} color="#F24726" size={10} />
    </Grid>
  );
}
