import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  IconButtonProps,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { OrderHistoryApi } from "../../api/OrderHistory.api";
import { AuthStorage } from "../storage/AuthStorage";
import { Reorder_Product, Total } from "../../typings";
import { RepeatOrderDetail } from "./RepeatOrderDetail";
import { Spinner } from "../Spinner";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const RepeatOrderCard = () => {
  //! for history
  const history = useNavigate();
  const authStorage = AuthStorage.getInstance();
  const [orders, setOrders] = React.useState<Reorder_Product[] | undefined>(
    undefined
  );
  const [order_id, setOrderId] = React.useState<string>("");
  const [amount, setAmount] = useState<Total>();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    let cancelled = false;
    const fetchHistory = async () => {
      await OrderHistoryApi.getOrderHistory().then((res) => {
        try {
          authStorage.getUser();
        } catch (error) {
          history("/login");
        }
        if (!cancelled) {
          if (res.code === 410) {
            authStorage.removeUser();
          }
          setOrderId(res.psdata[res.psdata.length - 1].id_order);
        }
      });
    };

    fetchHistory();

    return () => {
      cancelled = true;
    };
  }, [authStorage]);

  useEffect(() => {
    let cancelled = false;
    const fetchOrder = async () => {
      await OrderHistoryApi.getReorderHistory(order_id).then((res) => {
        if (!cancelled) {
          if (res.code === 410) {
            authStorage.removeUser();
          }

          if (res.success === true) {
            setAmount(res.psdata.amounts.totals.total);

            const products = [];
            for (const key in res.psdata.products) {
              products.push(res.psdata.products[key]);
            }
            setOrders(products);
          }
        }
      });
      return () => {
        cancelled = true;
      };
    };
    fetchOrder();
  }, [authStorage, order_id]);
  //   ! for reorder
  const handleReorder = () => {
    toast.success("Tra pochi secondi verrai ridirezionato al checkout");
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Box m={1}>
        <Card>
          <CardContent
            style={{
              backgroundColor: "#f5f5f5",
            }}
          >
            <Typography variant="subtitle1">
              Vuoi ripetere il tuo ultimo ordine?
            </Typography>
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="caption">
                <i>
                  Puoi comunque aggiungere togliere o cambiare qualsiasi
                  prodotto nel carrello
                </i>
              </Typography>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </Box>
          </CardContent>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Box>
                {orders !== undefined ? (
                  orders.length > 0 ? (
                    orders.map((order, idx: number) => {
                      return (
                        <RepeatOrderDetail
                          key={idx}
                          name={order.name}
                          quantity={order.quantity}
                          imageUrl={order.cover.small.url}
                        />
                      );
                    })
                  ) : (
                    <Typography>Nessun ordine recente</Typography>
                  )
                ) : (
                  Spinner(true)
                )}
              </Box>

              {orders != null && orders.length > 0 ? (
                <React.Fragment>
                  <Grid
                    container
                    direction={"column"}
                    alignContent={"end"}
                    mb={1}
                    p={1}
                  >
                    <Typography variant="subtitle1">
                      Totale: {amount?.value}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        handleReorder();

                        window.location.replace(
                          `https://www.dogbauer.it/ordine?submitReorder=&id_order=${order_id}`
                        );
                        window.location.replace(
                          "https://www.dogbauer.it/carrello?action=show"
                        );
                      }}
                    >
                      Riordina
                    </Button>
                  </Grid>
                </React.Fragment>
              ) : null}
            </CardContent>
          </Collapse>
        </Card>
      </Box>
    </React.Fragment>
  );
};

export default RepeatOrderCard;
