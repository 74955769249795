/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Card,
  CardContent,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import { IconButtonProps } from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Product } from "../../typings";
type ShowMoreProps = {
  loading: boolean;
  AddToCart: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: Product
  ) => void;
  product: Product;
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
const ShowMoreComponent = ({ loading, AddToCart, product }: ShowMoreProps) => {
  const [innerText, setInnerText] = useState("");
  const [expanded, setExpanded] = React.useState(false);
  useEffect(() => {
    function extractContent(value: any) {
      const extract_text = document
        .createRange()
        .createContextualFragment(value);
      if (extract_text.textContent) {
        setInnerText(extract_text.textContent);
      }
    }

    extractContent(product.description);
  }, []);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <React.Fragment>
      <Typography variant="subtitle1" px={1}>
        Descrizione{" "}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Typography>

      <Collapse in={expanded}>
        <CardContent>
          <div dangerouslySetInnerHTML={{ __html: product.description }} />
        </CardContent>
      </Collapse>
    </React.Fragment>
  );
};

export default ShowMoreComponent;
