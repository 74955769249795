import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ContactsIcon from "@mui/icons-material/Contacts";
import HomeIcon from "@mui/icons-material/Home";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import LogoutIcon from "@mui/icons-material/Logout";
import CategoryIcon from "@mui/icons-material/Category";

import {
  Box,
  ClickAwayListener,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DogBauerContext } from "../../context/DogBauer.context";
import { AuthStorage } from "../storage/AuthStorage";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

type Props = {
  open: boolean;
  toggleDrawer: (active: boolean) => void;
};

const SidebarListItem = ({ open, toggleDrawer }: Props) => {
  const { user, setUser } = useContext(DogBauerContext);
  const [openCollapse, setOpenCollapse] = useState(false);

  const handleClick = () => {
    setOpenCollapse(!openCollapse);
  };
  const router = useNavigate();

  const categoryItem = (idCategory: string, text: string) => (
    <ListItemButton
      onClick={() => {
        router("/categoria/" + idCategory);
        toggleDrawer(false);
      }}
    >
      <ListItemText>
        <Box pl={4}>{text}</Box>
      </ListItemText>
    </ListItemButton>
  );
  return (
    <ClickAwayListener onClickAway={() => toggleDrawer(false)}>
      <Box mt={2} pt={5}>
        <List>
          <ListItemButton
            onClick={() => {
              router("/");
              toggleDrawer(false);
            }}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText>Home</ListItemText>
          </ListItemButton>
          <Divider />
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <CategoryIcon />
            </ListItemIcon>
            <ListItemText>Categorie</ListItemText>
            {openCollapse ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <List style={{ backgroundColor: "#f5f5f5" }}>
              {categoryItem("83", "Cane")}
              {categoryItem("86", "Gatto")}
              {categoryItem("100", "Pulizia & igiene")}
            </List>
          </Collapse>
          <Divider />
          <ListItemButton
            onClick={() => {
              router("/cart");
              toggleDrawer(false);
            }}
          >
            <ListItemIcon>
              <Inventory2Icon />
            </ListItemIcon>
            <ListItemText>Carrello</ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              router("/orders");
              toggleDrawer(false);
            }}
          >
            <ListItemIcon>
              <Inventory2Icon />
            </ListItemIcon>
            <ListItemText>Ordini</ListItemText>
          </ListItemButton>
          <Divider />
          <ListItemButton
            component="a"
            href="https://www.dogbauer.it/il-mio-account"
          >
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText>Account</ListItemText>
          </ListItemButton>
          <Divider />
          <ListItemButton
            component="a"
            href="https://www.dogbauer.it/indirizzi"
          >
            <ListItemIcon>
              <ContactsIcon />
            </ListItemIcon>
            <ListItemText>Indirizzi</ListItemText>
          </ListItemButton>
          <Divider />
          <ListItemButton
            onClick={() => {
              setUser(null);
              AuthStorage.getInstance().removeUser();
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </ListItemButton>
        </List>
      </Box>
    </ClickAwayListener>
  );
};
const SidebarDrawer = ({ open, toggleDrawer }: Props) => {
  return (
    <Drawer anchor="right" open={open} variant="temporary">
      <SidebarListItem open={open} toggleDrawer={toggleDrawer} />
    </Drawer>
  );
};
export default SidebarDrawer;
