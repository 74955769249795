import {  createContext, useState } from "react";

export const DogBauerContext = createContext();

export const DogBauerProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [cart, setCart] = useState(null);

  const value = { user, setUser, cart, setCart };
  return (
    <DogBauerContext.Provider value={value}>
      {children}
    </DogBauerContext.Provider>
  );
};
