import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import { Badge, IconButton, Link } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useContext, useEffect } from "react";
import { ProductApi } from "../../api/Product.api";
import { DogBauerContext } from "../../context/DogBauer.context";

type Props = {
  openDrawer(open: boolean): void;
  opened: boolean;
};

const Header = ({ openDrawer, opened }: Props) => {
  const history = useNavigate();
  const { cart, setCart } = useContext(DogBauerContext);
  useEffect(() => {
    const getCart = async () => {
      try {
        const response = await ProductApi.getCart();
        setCart(response.psdata);
      } catch (error) {}
    };

    getCart();
    return () => {};
  }, []);
  return (
    <Box
      sx={{
        flexGrow: 1,
        height: "60px",
      }}
    >
      <ToastContainer />
      <AppBar
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "between",
          alignItems: "center",
          height: "60px",
        }}
        elevation={0}
        position="fixed"
      >
        <Typography
          onClick={() => history("/")}
          style={{
            color: "white",
            fontSize: "28px",
            marginLeft: "12px",
            cursor: "pointer",
            flex: 1,
          }}
          component="span"
        >
          DogBauer
        </Typography>
        <Link
          href="https://www.dogbauer.it/carrello"
          style={{ color: "black" }}
          onClick={() =>
            toast.success("Tra pochi secondi verrai ridirezionato al carrello")
          }
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="cart"
            sx={{ mr: 2 }}
          >
            {cart === null || cart.products_count === 0 ? (
              <ShoppingCart />
            ) : (
              <Badge badgeContent={cart.products_count} color="primary">
                <ShoppingCart />
              </Badge>
            )}
          </IconButton>
        </Link>
        <IconButton
          onClick={() => openDrawer(!opened)}
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
      </AppBar>
    </Box>
  );
};

export default Header;
