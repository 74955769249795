import { Box } from "@mui/material";
import uuid from "react-uuid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProductApi } from "../../api/Product.api";
import { Spinner } from "../Spinner";
import {
  TypeCategoryId,
  TypeProductRow,
  TypeProductsRows,
} from "../../types/global.types";
import { Product } from "../../typings";
import { AuthStorage } from "../storage/AuthStorage";
import ProductRow from "./ProductRow";
interface IProductListParams {
  category_id: string | number;
  filter: string;
}

const ProductList = (params: IProductListParams) => {
  let navigate = useNavigate();
  const { filter } = params;
  const authStorage = AuthStorage.getInstance();
  const [products, setProducts] = useState<TypeProductsRows>([]);
  const [loading, setLoading] = useState(true);
  const [categoryId, setCategoryId] = useState<TypeCategoryId>("");

  const fetchProducts = async () => {
    setLoading(true);
    await ProductApi.getProducts(params.category_id)
      .then((res) => {
        if (res.code == 410) {
          authStorage.removeUser();
        }
        setProducts(transformListInTableWithTwoColumn(res));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      await ProductApi.getProductByQuery(filter)
        .then((res) => {
          setProducts(transformListInTableWithTwoColumn(res));
        })
        .finally(() => setLoading(false));
    };

    getProducts();
  }, [filter]);

  if (params.category_id !== categoryId) {
    try {
      authStorage.getUser();
      fetchProducts();
      setCategoryId(params.category_id);
    } catch (e) {
      navigate("/login");
    }
  }
  return (
    <React.Fragment>
      <Box m={3}>{Spinner(loading)}</Box>
      {products.map((row) => (
        <ProductRow key={uuid()} row={row} />
      ))}
    </React.Fragment>
  );
};

const transformListInTableWithTwoColumn = (res: {
  code: number;
  psdata: { products: Product[] };
}): TypeProductsRows => {
  let cols: TypeProductRow = [];
  let rows: TypeProductsRows = [];
  if (!res["psdata"]) return rows;
  res.psdata.products.forEach((element, index) => {
    cols.push(element);
    if (index % 2 === 0) {
      return;
    }
    rows.push(cols);
    cols = [];
  });
  return rows;
};
export default ProductList;
