import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { OrderHistoryApi } from "../../api/OrderHistory.api";
import { AuthStorage } from "../storage/AuthStorage";
import { OrderType } from "../../typings";
import { OrderCard } from "./OrderCard";

export const OrderHistory = () => {
  const [orders, setOrders] = React.useState<OrderType[]>([]);
  const authStorage = AuthStorage.getInstance();
  useEffect(() => {
    let cancelled = false;
    const fetchHistory = async () => {
      await OrderHistoryApi.getOrderHistory().then((res) => {
        if (!cancelled)
          if (res.code == 410) {
            authStorage.removeUser();
          }
        if (res.success) {
          setOrders(res.psdata);
        }
      });

      // getting order point
    };
    fetchHistory();
    return () => {
      cancelled = true;
    };
  }, []);
  return (
    <React.Fragment>
      {orders.map((order, idx: number) => {
        return (
          <OrderCard
            key={idx}
            total={order.total_paid}
            code={order.reference}
            date={order.date_add}
            status={order.order_state}
            order_id={order.id_order}
          />
        );
      })}

      {orders.length === 0 && (
        <Typography variant="h6" textAlign={"center"}>
          Nessun ordine
        </Typography>
      )}
    </React.Fragment>
  );
};
