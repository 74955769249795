import { Grid, Typography } from "@mui/material";
import Coupons from "../components/points/coupon/Coupons";
import { OrderHistory } from "../components/order/OrderHistory";

const OrdersScreen = () => {
  return (
    <Grid container p={1} direction={"column"}>
      <Grid item>
        <Typography variant="h1" marginBottom={2} textAlign={"center"}>
          I tuoi ordini
        </Typography>
      </Grid>
      <Grid item mb={2}>
        <Coupons />
      </Grid>
      <Grid item>
        <OrderHistory />
      </Grid>
    </Grid>
  );
};

export default OrdersScreen;
