import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { CouponsType } from "../../../typings";
import CouponCard from "./CouponCard";

const CouponsComponents = (props: { coupons: Array<CouponsType> }) => {
  return (
    <React.Fragment>
      <Typography variant="h6">Coupon utilizzabili</Typography>
      <Box m={2} />
      {props.coupons.map((coupon: CouponsType, idx: number) => {
        return <CouponCard coupon={coupon} key={idx} />;
      })}

      {props.coupons.length === 0 && (
        <Typography variant="subtitle2">Nessun coupon disponibile</Typography>
      )}
    </React.Fragment>
  );
};

export default CouponsComponents;
